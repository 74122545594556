export const THEME_COLOR = '#08559D'
export const CURRENCY_ROUNDING = 100
// Up to and including
export const MAX_BOOKING_CHILD_AGE = 11
export const COOKIE_USER_ID = 'USER_UID'
export const STORYBLOK_CLIENT = 'storyblok'
export const OG_URL = 'https://connections.be'
export const DEFAULT_CHECKED_BAGGAGE_WEIGHT = 20
export const BOOKING_SESSION_EXPIRED_ERROR = 'BOOKING_SESSION_EXPIRED'

// Languages

export const LANGUAGE_NL = 'nl'
export const LANGUAGE_EN = 'en'
export const LANGUAGE_FR = 'fr'
export const LANGUAGE_NL_BE = 'nl-be'
export const LANGUAGE_FR_BE = 'fr-be'

export const DEFAULT_LANGUAGE_API = LANGUAGE_NL
export const AVAILABLE_LANGUAGES_API = [LANGUAGE_NL, LANGUAGE_EN, LANGUAGE_FR]

export const DEFAULT_LANGUAGE_WEB = LANGUAGE_NL_BE
export const DEFAULT_LANGUAGE_WEB_SIMPLE = LANGUAGE_NL
export const AVAILABLE_LANGUAGES_WEB = [
    LANGUAGE_NL,
    LANGUAGE_NL_BE,
    LANGUAGE_FR,
    LANGUAGE_FR_BE,
    LANGUAGE_EN,
]

export const AVAILABLE_LANGUAGES_SHOPS = [
    LANGUAGE_NL_BE,
    LANGUAGE_FR_BE,
    LANGUAGE_EN,
]

// Entities

export const PAGE_ENTITY = 'Page'
export const TOUR_ENTITY = 'Tour'
export const SHOP_ENTITY = 'Shop'
export const BREAK_ENTITY = 'Break'
export const HOTEL_ENTITY = 'Hotel'
export const EXPERT_ENTITY = 'Expert'
export const ACTIVITY_ENTITY = 'Activity'
export const BLOG_POST_ENTITY = 'BlogPost'
export const DESTINATION_ENTITY = 'Destination'

// Db

export const ORDER_BY_ASC = 'ASC'
export const ORDER_BY_DESC = 'DESC'
export const DEFAULT_PAGE_SIZE = 25

// Auth tables

export const DB_TABLE_SHOPS = 'shops'
export const DB_TABLE_MEMBERS = 'members'
export const DB_TABLE_SESSIONS = 'sessions'
export const DB_TABLE_ACCOUNTS = 'accounts'
export const DB_TABLE_AUTH_USERS = 'auth_users'
export const DB_TABLE_VERIFICATIONS = 'verifications'
export const DB_TABLE_AUTH_INVITATIONS = 'auth_invitations'

export const DB_TABLE_USERS = 'users'
export const DB_TABLE_ROLES = 'roles'
export const DB_TABLE_JOURNEYS = 'journeys'
export const DB_TABLE_BOOKINGS = 'bookings'
export const DB_TABLE_PAYMENTS = 'payments'
export const DB_TABLE_GIFTCARDS = 'giftcards'
export const DB_TABLE_USER_ROLES = 'user_roles'
export const DB_TABLE_INVITATIONS = 'invitations'
export const DB_TABLE_BOOKING_ITEMS = 'booking_items'
export const DB_TABLE_BOOKING_USERS = 'booking_users'
export const DB_TABLE_REFRESH_TOKENS = 'refresh_tokens'
export const DB_TABLE_SEARCH_RESULTS = 'search_results'
export const DB_TABLE_FORM_SUBMISSIONS = 'form_submissions'
export const DB_TABLE_BOOKING_GIFTCARDS = 'booking_giftcards'
export const DB_TABLE_BOOKING_TRAVELERS = 'booking_travelers'
export const DB_TABLE_CHEAPEST_PRICE_FORKS = 'cheapest_price_forks'
export const DB_TABLE_FLIGHT_SEARCH_RESULTS = 'flight_search_results'
export const DB_TABLE_FORM_SUBMISSIONS_TOUR = 'form_submissions_tour'
export const DB_TABLE_TRAVEL_BOOSTER_CLIENTS = 'travel_booster_clients'
export const DB_TABLE_FLIGHT_SEARCH_RESULTS_SYNC = 'flight_search_results_sync'
export const DB_TABLE_BOOKING_TRAVELERS_BOOKING_ITEMS =
    'booking_travelers_booking_items'

// Continents

export const ASIA = 'AS'
export const AFRICA = 'AF'
export const EUROPE = 'EU'
export const MIDDLE_EAST = 'ME'
export const NORTH_AMERICA = 'NA'
export const LATIN_AMERICA = 'SA'
export const AUSTRALIA_AND_NEW_ZEALAND = 'AU'
export const EXPERT_CONTINENTS = [
    ASIA,
    AFRICA,
    EUROPE,
    MIDDLE_EAST,
    NORTH_AMERICA,
    LATIN_AMERICA,
    AUSTRALIA_AND_NEW_ZEALAND,
]

// Cards

export const CARD = 'Card'
export const SHOP_CARD = 'ShopCard'
export const PAGE_CARD = 'PageCard'
export const TOUR_CARD = 'TourCard'
export const BREAK_CARD = 'BreakCard'
export const HOTEL_CARD = 'HotelCard'
export const EXPERT_CARD = 'ExpertCard'
export const ACTIVITY_CARD = 'ActivityCard'
export const BLOG_POST_CARD = 'BlogPostCard'
export const DESTINATION_CARD = 'DestinationCard'
export const ICONS_FEATURES_CARD = 'IconFeaturesCard'
export const AIRLINE_PRICES_CARD = 'AirlinePricesCard'
export const WHY_CONNECTIONS_REASON_CARD = 'IconFeaturesCard'

// Columns

export const COLUMN_LEFT = 'column_left'
export const COLUMN_RIGHT = 'column_right'

export const COLUMN_WIDTH_FULL = 'full'
export const COLUMN_WIDTH_LARGE = 'large'
export const COLUMN_WIDTH_SMALL = 'small'
export const COLUMN_WIDTH_MEDIUM = 'medium'

export const COLUMN_SPACING_NONE = 'none'
export const COLUMN_SPACING_SMALL = 'small'
export const COLUMN_SPACING_LARGE = 'large'
export const COLUMN_SPACING_MEDIUM = 'medium'

// Styling and positioning

export const BACKGROUND_BLUE = 'blue'
export const BACKGROUND_WHITE = 'white'
export const BACKGROUND_BLACK = 'black'
export const BACKGROUND_GRAY = 'gray-100'
export const BACKGROUND_GRAY_LIGHT = 'gray-50'

export const TEXT_BLUE = 'blue'
export const TEXT_WHITE = 'white'
export const TEXT_BLACK = 'black'
export const TEXT_PRIMARY = 'primary'

export const SECTION_SPACING_SMALL = 'small'
export const SECTION_SPACING_LARGE = 'large'
export const SECTION_SPACING_MEDIUM = 'medium'
export const SECTION_SPACING_SMALLER = 'smaller'

export const ALIGN_TOP = 'top'
export const ALIGN_LEFT = 'left'
export const ALIGN_RIGHT = 'right'
export const ALIGN_BOTTOM = 'bottom'
export const ALIGN_CENTER = 'center'
export const ALIGN_TOP_LEFT = 'top-left'
export const ALIGN_TOP_RIGHT = 'top-right'
export const ALIGN_BOTTOM_LEFT = 'bottom-left'
export const ALIGN_CENTER_LEFT = 'center-left'
export const ALIGN_BOTTOM_RIGHT = 'bottom-right'
export const ALIGN_CENTER_RIGHT = 'center-right'

export const CONTAINER_NONE = 'none'
export const CONTAINER_SMALL = 'small'
export const CONTAINER_MEDIUM = 'medium'
export const CONTAINER_DEFAULT = 'default'
export const CONTAINER_EXTRA_SMALL = 'extra-small'

export const IMAGE_SQUARE = 'square'
export const IMAGE_STRETCH = 'stretch'
export const IMAGE_PORTRAIT = 'portrait'
export const IMAGE_LANDSCAPE = 'landscape'

export const SIZE_FULL = 'full'
export const SIZE_SMALL = 'small'
export const SIZE_LARGE = 'large'
export const SIZE_MEDIUM = 'medium'
export const SIZE_DEFAULT = 'default'

export const SPACING_SMALL = 'small'
export const SPACING_LARGE = 'large'
export const SPACING_MEDIUM = 'medium'

// Flight types

export const FLIGHT_TYPE_TO = 0
export const FLIGHT_TYPE_FROM = 1
export const FLIGHT_TYPE_FLIGHT = 'flight'

export const FARE_TYPE_FIRST = 'first'
export const FARE_TYPE_COACH = 'coach'
export const FARE_TYPE_ECONOMY = 'economy'
export const FARE_TYPE_BUSINESS = 'business'
export const FARE_TYPE_ECONOMY_PLUS = 'economy-plus'

// QSM tabs

export const QSM_TAB_CARS = 'car'
export const QSM_TAB_TRIP = 'trip'
export const QSM_TAB_SHOPS = 'shop'
export const QSM_TAB_HOTELS = 'hotel'
export const QSM_TAB_TRAVEL = 'travel'
export const QSM_TAB_EXPERTS = 'expert'
export const QSM_TAB_FLIGHTS = 'flight'
export const QSM_TAB_EXPERIENCES = 'experience'
export const QSM_TAB_FLIGHT_HOTEL = 'flight-hotel'

// Notifications

export const NOTIFICATION_TYPE_ERROR = 'ERROR'
export const NOTIFICATION_TYPE_WARNING = 'WARNING'
export const NOTIFICATION_TYPE_SUCCESS = 'SUCCESS'

// Filtering

export const ALL_OPTIONS = 'all'
export const OPTION_CHECKED = 'checked'
export const OPTION_UNCHECKED = 'unchecked'

export const OPTION_ADDON = 'add-on'
export const OPTION_INCLUDED = 'included'
export const OPTION_EXCLUDED = 'excluded'

// Sorting

export const SORT_OPTION_CHEAPEST = 'cheapest'
export const SORT_OPTION_SHORTEST = 'shortest'
export const SORT_OPTION_RECOMMENDED = 'recommended'

// Users

export const GENDER_MALE = 'Male'
export const GENDER_FEMALE = 'Female'
export const GENDER_OTHER = 'Other'
export const GENDER_UNSPECIFIED = 'UNSPECIFIED'

export const GENDER_TO_CODE = {
    [GENDER_MALE]: 1,
    [GENDER_FEMALE]: 0,
    [GENDER_UNSPECIFIED]: 2,
}

export const DEFAULT_PASSWORD = 'secret'
export const DEFAULT_INVITATION_TOKEN = 'invitation-token'

export const USER_TYPE_USER = 'USER'
export const USER_TYPE_ADMIN = 'ADMIN'
export const USER_TYPE_GUEST = 'GUEST'
export const USER_TYPES = [USER_TYPE_USER, USER_TYPE_ADMIN, USER_TYPE_GUEST]

export const ADMIN_ROLE_SHOP = 'SHOP'
export const ADMIN_ROLE_OWNER = 'OWNER'
export const ADMIN_ROLE_MEMBER = 'MEMBER'
export const USER_ROLES = [ADMIN_ROLE_SHOP, ADMIN_ROLE_OWNER, ADMIN_ROLE_MEMBER]

export const INVITATION_STATUS_PENDING = 'PENDING'
export const INVITATION_STATUS_EXPIRED = 'EXPIRED'
export const INVITATION_STATUS_ACCEPTED = 'ACCEPTED'

export const USER_ROLE_USER = 'USER'
export const USER_ROLE_ADMIN = 'ADMIN'
export const USER_ROLE_AGENT = 'AGENT'

export const AUTH_USER_ROLES = [
    USER_ROLE_USER,
    USER_ROLE_ADMIN,
    USER_ROLE_AGENT,
]

export const SHOP_ROLE_OWNER = 'OWNER'
export const SHOP_ROLE_AGENT = 'AGENT'

// Flight classes

export const CLASS_TYPE_COACH = 'COACH'
export const CLASS_TYPE_FIRST = 'FIRST'
export const CLASS_TYPE_ECONOMY = 'ECONOMY'
export const CLASS_TYPE_PREMIUM = 'PREMIUM'
export const CLASS_TYPE_BUSINESS = 'BUSINESS'
export const CLASS_TYPE_ECONOMY_PLUS = 'ECONOMY_PLUS'

// Flight modes

export const FLIGHT_MODE_ONE_WAY = 'ONE_WAY'
export const FLIGHT_MODE_ROUND_TRIP = 'ROUND_TRIP'
export const FLIGHT_MODE_MULTI_CITY = 'MULTI_CITY'

// Search

export const CITY_CODE_AMS = 'AMS'
export const CITY_CODE_ANR = 'ANR'
export const CITY_CODE_BRU = 'BRU'
export const CITY_CODE_CDG = 'CDG'
export const CITY_CODE_CRL = 'CRL'
export const CITY_CODE_DUS = 'DUS'
export const CITY_CODE_EIN = 'EIN'
export const CITY_CODE_LIL = 'LIL'
export const DEPARTURE_CODES = [
    CITY_CODE_AMS,
    CITY_CODE_ANR,
    CITY_CODE_BRU,
    CITY_CODE_CDG,
    CITY_CODE_CRL,
    CITY_CODE_DUS,
    CITY_CODE_EIN,
    CITY_CODE_LIL,
]

export const SEARCH_ENGINE_AIRPORTS_EN = 'airports-en'
export const SEARCH_ENGINE_AIRPORTS_NL = 'airports-nl'
export const SEARCH_ENGINE_AIRPORTS_FR = 'airports-fr'
export const SEARCH_ENGINES_AIRPORTS = [
    SEARCH_ENGINE_AIRPORTS_EN,
    SEARCH_ENGINE_AIRPORTS_NL,
    SEARCH_ENGINE_AIRPORTS_FR,
]

export const SEARCH_ENGINE_CITIES_EN = 'cities-en'
export const SEARCH_ENGINE_CITIES_NL = 'cities-nl'
export const SEARCH_ENGINE_CITIES_FR = 'cities-fr'
export const SEARCH_ENGINES_CITIES = [
    SEARCH_ENGINE_CITIES_EN,
    SEARCH_ENGINE_CITIES_NL,
    SEARCH_ENGINE_CITIES_FR,
]

export const SEARCH_ENGINE_CARS_EN = 'car-rental-locations-en'
export const SEARCH_ENGINE_CARS_NL = 'car-rental-locations-nl'
export const SEARCH_ENGINE_CARS_FR = 'car-rental-locations-fr'
export const SEARCH_ENGINES_CARS = [
    SEARCH_ENGINE_CARS_EN,
    SEARCH_ENGINE_CARS_NL,
    SEARCH_ENGINE_CARS_FR,
]

// Form types

export const FORM_TYPE_TOUR = 'TOUR'
export const FORM_TYPE_DEFAULT = 'DEFAULT'
export const FORM_TYPE_EXPERIENCES = 'EXPERIENCES'
export const FORM_TYPE_TOUR_TAILORMADE = 'TOUR_TAILORMADE'
export const FORM_TYPE_CAMPERVANS_MOTORHOMES = 'CAMPERVANS_MOTORHOMES'

// Jotform controls

export const JOTFORM_CONTROL_TEXT = 'control_text'
export const JOTFORM_CONTROL_HEAD = 'control_head'
export const JOTFORM_CONTROL_EMAIL = 'control_email'
export const JOTFORM_CONTROL_PHONE = 'control_phone'
export const JOTFORM_CONTROL_RADIO = 'control_radio'
export const JOTFORM_CONTROL_BUTTON = 'control_button'
export const JOTFORM_CONTROL_NUMBER = 'control_number'
export const JOTFORM_CONTROL_ADDRESS = 'control_address'
export const JOTFORM_CONTROL_TEXTBOX = 'control_textbox'
export const JOTFORM_CONTROL_DATETIME = 'control_datetime'
export const JOTFORM_CONTROL_DROPDOWN = 'control_dropdown'
export const JOTFORM_CONTROL_FULLNAME = 'control_fullname'
export const JOTFORM_CONTROL_TEXTAREA = 'control_textarea'
export const JOTFORM_CONTROL_CHECKBOX = 'control_checkbox'

// Search result types

export const SEARCH_RESULT_TYPE_TOUR = 'TOUR'
export const SEARCH_RESULT_TYPE_TOURS = 'TOURS'
export const SEARCH_RESULT_TYPE_FLIGHTS = 'FLIGHTS'
export const SEARCH_RESULT_TYPE_DYNAMIC_PACKAGE = 'DYNAMIC_PACKAGE'
export const SEARCH_RESULT_TYPE_TEMPLATE_PRICING = 'TEMPLATE_PRICING'
export const SEARCH_RESULT_TYPE_ROOM_BASED_PACKAGE = 'ROOM_BASED_PACKAGE'

// Journey statuses

export const JOURNEY_STATUS_SESSION_ACTIVE = 'SESSION_ACTIVE'
export const JOURNEY_STATUS_SESSION_EXPIRED = 'SESSION_EXPIRED'

// Booking drivers

export const DRIVER_HATEC = 'hatec'
export const DRIVER_GREEN_TRIPPER = 'green-tripper'
export const DRIVER_TRAVEL_BOOSTER = 'travel-booster'
export const BOOKING_DRIVERS = [
    DRIVER_HATEC,
    DRIVER_GREEN_TRIPPER,
    DRIVER_TRAVEL_BOOSTER,
]

// Booking types

export const BOOKING_TYPE_TOUR = 'TOUR'
export const BOOKING_TYPE_HOTEL = 'HOTEL'
export const BOOKING_TYPE_BREAK = 'BREAK'
export const BOOKING_TYPE_FLIGHT = 'FLIGHT'
export const BOOKING_TYPE_TEMPLATE = 'TEMPLATE'
export const BOOKING_TYPE_GIFTCARD = 'GIFTCARD'
export const BOOKING_TYPE_ACTIVITY = 'ACTIVITY'
export const BOOKING_TYPE_TRANSPORT = 'TRANSPORT'
export const BOOKING_TYPE_DYNAMIC_TEMPLATE = 'DYNAMIC_TEMPLATE'
export const BOOKING_TYPES = [
    BOOKING_TYPE_TOUR,
    BOOKING_TYPE_BREAK,
    BOOKING_TYPE_FLIGHT,
    BOOKING_TYPE_TEMPLATE,
    BOOKING_TYPE_GIFTCARD,
    BOOKING_TYPE_ACTIVITY,
    BOOKING_TYPE_TRANSPORT,
    BOOKING_TYPE_DYNAMIC_TEMPLATE,
]

// Booking statuses

export const BOOKING_STATUS_FAILED = 'FAILED'
export const BOOKING_STATUS_STARTED = 'STARTED'
export const BOOKING_STATUS_CREATED = 'CREATED'
export const BOOKING_STATUS_CONFIRMED = 'CONFIRMED'
export const BOOKING_STATUS_COMPLETED = 'COMPLETED'
export const BOOKING_STATUS_CANCELLED = 'CANCELLED'

// Booking item types

export const BOOKING_ITEM_TYPE_CAR = 'CAR'
export const BOOKING_ITEM_TYPE_TOUR = 'TOUR'
export const BOOKING_ITEM_TYPE_HOTEL = 'HOTEL'
export const BOOKING_ITEM_TYPE_FLIGHT = 'FLIGHT'
export const BOOKING_ITEM_TYPE_BAG_FEE = 'BAG_FEE'
export const BOOKING_ITEM_TYPE_GIFTCARD = 'GIFTCARD'
export const BOOKING_ITEM_TYPE_ACTIVITY = 'ACTIVITY'
export const BOOKING_ITEM_TYPE_TRANSPORT = 'TRANSPORT'
export const BOOKING_ITEM_TYPE_INSURANCE = 'INSURANCE'
export const BOOKING_ITEM_TYPE_FILE_COSTS = 'FILE_COSTS'
export const BOOKING_ITEM_TYPE_EMISSION_COSTS = 'EMISSION_COSTS'
export const BOOKING_ITEM_TYPE_SEAT_RESERVATION = 'SEAT_RESERVATION'
export const BOOKING_ITEM_TYPES = [
    BOOKING_ITEM_TYPE_CAR,
    BOOKING_ITEM_TYPE_TOUR,
    BOOKING_ITEM_TYPE_HOTEL,
    BOOKING_ITEM_TYPE_FLIGHT,
    BOOKING_ITEM_TYPE_BAG_FEE,
    BOOKING_ITEM_TYPE_GIFTCARD,
    BOOKING_ITEM_TYPE_ACTIVITY,
    BOOKING_ITEM_TYPE_TRANSPORT,
    BOOKING_ITEM_TYPE_INSURANCE,
    BOOKING_ITEM_TYPE_FILE_COSTS,
    BOOKING_ITEM_TYPE_EMISSION_COSTS,
    BOOKING_ITEM_TYPE_SEAT_RESERVATION,
]

// Traveler types

export const TRAVELER_TYPE_ADULT = 'ADULT'
export const TRAVELER_TYPE_CHILD = 'CHILD'
export const TRAVELER_TYPE_INFANT = 'INFANT'

export const TRAVELER_TYPE_CODE_TO_TYPE = {
    ADT: TRAVELER_TYPE_ADULT,
    CNN: TRAVELER_TYPE_CHILD,
    INF: TRAVELER_TYPE_INFANT,
}

// Insurance types

export const INSURANCE_TRAVEL = 'TRAVEL'
export const INSURANCE_CANCEL = 'CANCEL'
export const INSURANCE_MORA_MORA = 'MORA_MORA'
export const INSURANCE_TRAVEL_CANCEL = 'TRAVEL_CANCEL'
export const INSURANCE_FLIGHT_GUARANTEE = 'FLIGHT_GUARANTEE'
export const INSURANCE_TYPES = [
    INSURANCE_TRAVEL,
    INSURANCE_CANCEL,
    INSURANCE_MORA_MORA,
    INSURANCE_TRAVEL_CANCEL,
    INSURANCE_FLIGHT_GUARANTEE,
]

export const INSURANCE_PER_DAY = 'PER_DAY'
export const INSURANCE_TICKET_PRICE = 'FLAT_RATE'
export const INSURANCE_FLAT_RATE = 'TICKET_PRICE'

// Insurance products

export const INSURANCE_PRODUCT_MORA_MORA = 'Mora Mora (optional)'
export const INSURANCE_PRODUCT_FLIGHT_GUARANTEE = 'Flight Guarantee'
export const INSURANCE_PRODUCT_CANCEL = 'Cancellation Insurance Temporary'
export const INSURANCE_PRODUCT_TRAVEL_CANCEL = 'Combi Insurance Temporary'
export const INSURANCE_PRODUCT_TRAVEL_GLOBAL =
    'Travel Assistance Insurance Temporary (World)'
export const INSURANCE_PRODUCT_TRAVEL_EUROPE =
    'Travel Assistance Insurance Temporary (Europe)'

export const INSURANCE_PRODUCTS_TYPES = {
    [INSURANCE_PRODUCT_CANCEL]: INSURANCE_CANCEL,
    [INSURANCE_PRODUCT_MORA_MORA]: INSURANCE_MORA_MORA,
    [INSURANCE_PRODUCT_TRAVEL_GLOBAL]: INSURANCE_TRAVEL,
    [INSURANCE_PRODUCT_TRAVEL_EUROPE]: INSURANCE_TRAVEL,
    [INSURANCE_PRODUCT_TRAVEL_CANCEL]: INSURANCE_TRAVEL_CANCEL,
    [INSURANCE_PRODUCT_FLIGHT_GUARANTEE]: INSURANCE_FLIGHT_GUARANTEE,
}

// Giftcard statuses

export const GIFTCARD_STATUS_OPEN = 'OPEN'
export const GIFTCARD_STATUS_USED = 'USED'
export const GIFTCARD_STATUS_EXPIRED = 'EXPIRED'
export const GIFTCARD_STATUS_VERIFIED = 'VERIFIED'

export const FILE_COSTS_0_ATTRIBUTE_NAME = 'Online 0'

// Payment statuses

export const PAYMENT_STATUS_PAID = 'PAID'
export const PAYMENT_STATUS_UNKNOWN = 'UNKNOWN'
export const PAYMENT_STATUS_CREATED = 'CREATED'
export const PAYMENT_STATUS_REJECTED = 'REJECTED'
export const PAYMENT_STATUS_CANCELLED = 'CANCELLED'
export const PAYMENT_STATUS_UNCERTAIN = 'UNCERTAIN'

// Payment products

export const INGENICO_PAYMENT_PRODUCTS = [
    {
        id: 3012,
        name: 'Bancontact',
        travelBoosterId: 14,
    },
    {
        id: 3,
        name: 'Mastercard',
        travelBoosterId: 6,
    },
    {
        id: 1,
        name: 'Visa',
        travelBoosterId: 1,
    },
    {
        id: 2,
        name: 'American Express',
        travelBoosterId: 7,
    },
    {
        id: 117,
        name: 'Maestro',
        travelBoosterId: 10,
    },
]

// Hatec providers

// This is not used anywhere currently
// Cherimoya provided the test queue number so this is added to prepare for a testing scenario
export const HATEC_PROVIDER_TEST = 'test'
export const HATEC_PROVIDER_HATEC = 'Hatec'
export const HATEC_PROVIDER_ELSYARRES = 'ElsyArres'
export const HATEC_PROVIDER_FARELOGIX = 'Farelogix'
export const HATEC_PROVIDER_AIRGATEWAY = 'AirGateway'
export const HATEC_PROVIDER_TRAVEL_PORT = 'TravelPort'
export const HATEC_PROVIDER_TRAVELFUSION = 'Travelfusion'
export const HATEC_LOWCOST_PROVIDERS = [
    HATEC_PROVIDER_ELSYARRES,
    HATEC_PROVIDER_TRAVELFUSION,
]

// Hatec queues

export const HATEC_QUEUE_TEST = 80
export const HATEC_QUEUE_SUCCESS_FARELOGIX = 175
export const HATEC_QUEUE_SUCCESS_TRAVEL_PORT = 75
export const HATEC_QUEUE_CANCELLED_FARELOGIX = 194
export const HATEC_QUEUE_CANCELLED_TRAVEL_PORT = 74
export const HATEC_QUEUE_UNCERTAIN_FARELOGIX = 188
export const HATEC_QUEUE_UNCERTAIN_TRAVEL_PORT = 73
export const HATEC_QUEUE_NOT_UPDATED_FARELOGIX = 189
export const HATEC_QUEUE_NOT_UPDATED_TRAVEL_PORT = 72

// Hatec provider_queue

export const HATEC_PROVIDER_SUCCESS_QUEUES = {
    [HATEC_PROVIDER_TEST]: HATEC_QUEUE_TEST,
    [HATEC_PROVIDER_FARELOGIX]: HATEC_QUEUE_SUCCESS_FARELOGIX,
    [HATEC_PROVIDER_TRAVEL_PORT]: HATEC_QUEUE_SUCCESS_TRAVEL_PORT,
}
export const HATEC_PROVIDER_CANCELLED_QUEUES = {
    [HATEC_PROVIDER_FARELOGIX]: HATEC_QUEUE_CANCELLED_FARELOGIX,
    [HATEC_PROVIDER_TRAVEL_PORT]: HATEC_QUEUE_CANCELLED_TRAVEL_PORT,
}
export const HATEC_PROVIDER_UNCERTAIN_QUEUES = {
    [HATEC_PROVIDER_FARELOGIX]: HATEC_QUEUE_UNCERTAIN_FARELOGIX,
    [HATEC_PROVIDER_TRAVEL_PORT]: HATEC_QUEUE_UNCERTAIN_TRAVEL_PORT,
}
export const HATEC_PROVIDER_NOT_UPDATED_QUEUES = {
    [HATEC_PROVIDER_FARELOGIX]: HATEC_QUEUE_NOT_UPDATED_FARELOGIX,
    [HATEC_PROVIDER_TRAVEL_PORT]: HATEC_QUEUE_NOT_UPDATED_TRAVEL_PORT,
}

// Hatec cards

export const HATEC_CARD_VISA = 'VI'
export const HATEC_CARD_VISA_DEBIT = 'VD'
export const HATEC_CARD_MASTERCARD = 'CA'
export const HATEC_CARD_VISA_ELECTRON = 'VE'
export const HATEC_CARD_MASTERCARD_DEBIT = 'MP'
export const HATEC_CARD_AMERICAN_EXPRESS = 'AX'
export const HATEC_CARDS = [
    HATEC_CARD_VISA,
    HATEC_CARD_VISA_DEBIT,
    HATEC_CARD_MASTERCARD,
    HATEC_CARD_VISA_ELECTRON,
    HATEC_CARD_MASTERCARD_DEBIT,
    HATEC_CARD_AMERICAN_EXPRESS,
]

// Hatec typing

export const HATEC_PSEUDO_CITY_CODE_TEST = '312S'
export const HATEC_PSEUDO_CITY_CODE_FARELOGIX = 'AE6V'
export const HATEC_PSEUDO_CITY_CODE_TRAVEL_PORT = 'QL7'
export const HATEC_PSEUDO_CITY_CODES = {
    [HATEC_PROVIDER_FARELOGIX]: HATEC_PSEUDO_CITY_CODE_FARELOGIX,
    [HATEC_PROVIDER_TRAVEL_PORT]: HATEC_PSEUDO_CITY_CODE_TRAVEL_PORT,
}

export const HATEC_CLASS_FIRST = 4
export const HATEC_CLASS_ECONOMY = 1
export const HATEC_CLASS_BUSINESS = 2
export const HATEC_CLASS_ECONOMY_PLUS = 8
export const HATEC_CLASS_TYPES = {
    [CLASS_TYPE_FIRST]: HATEC_CLASS_FIRST,
    [CLASS_TYPE_ECONOMY]: HATEC_CLASS_ECONOMY,
    [CLASS_TYPE_BUSINESS]: HATEC_CLASS_BUSINESS,
    [CLASS_TYPE_ECONOMY_PLUS]: HATEC_CLASS_ECONOMY_PLUS,
}

export const HATEC_FLIGHT_MODES = {
    [FLIGHT_MODE_ONE_WAY]: 3,
    [FLIGHT_MODE_MULTI_CITY]: 2,
    [FLIGHT_MODE_ROUND_TRIP]: 1,
}

export const HATEC_MALE_SALUTATION = 'Mr'
export const HATEC_BOY_SALUTATION = 'Mstr'
export const HATEC_GIRL_SALUTATION = 'Miss'
export const HATEC_FEMALE_SALUTATION = 'Mrs'
export const HATEC_SALUTATIONS = [
    HATEC_BOY_SALUTATION,
    HATEC_MALE_SALUTATION,
    HATEC_GIRL_SALUTATION,
    HATEC_FEMALE_SALUTATION,
]

export const HATEC_TRAVELER_TYPE_ADULT = 'ADT'
export const HATEC_TRAVELER_TYPE_CHILD = 'CNN'
export const HATEC_TRAVELER_TYPE_INFANT = 'INF'
export const HATEC_TRAVELER_TYPES = [
    HATEC_TRAVELER_TYPE_ADULT,
    HATEC_TRAVELER_TYPE_CHILD,
    HATEC_TRAVELER_TYPE_INFANT,
]

export const HATEC_CHARGE_KEY_ADULT = 'Adt'
export const HATEC_CHARGE_KEY_CHILD = 'Chd'
export const HATEC_CHARGE_KEY_INFANT = 'Inf'

export const HATEC_BOARDING_OPTION_TYPE_BAGGAGE = 0
export const HATEC_BOARDING_OPTION_TYPE_SPEED_BOARDING = 2
export const HATEC_BOARDING_OPTION_TYPE_AIRPORT_CHECK_IN = 1

export const HATEC_PASSENGER_TYPES_BY_TRAVELER_TYPE = {
    [TRAVELER_TYPE_ADULT]: HATEC_TRAVELER_TYPE_ADULT,
    [TRAVELER_TYPE_CHILD]: HATEC_TRAVELER_TYPE_CHILD,
    [TRAVELER_TYPE_INFANT]: HATEC_TRAVELER_TYPE_INFANT,
}

export const FARE_RULE_PENALTIES = 'PENALTIES'
export const CHARGEABLE_INDICATOR_INCLUDED = 1
export const CHARGEABLE_INDICATOR_EXCLUDED = 2
export const CHARGEABLE_INDICATOR_OPTIONAL = 3

export const OPTIONAL_SERVICE_TYPE_BAGGAGE = 'Baggage'
export const OPTIONAL_SERVICE_TYPE_SEAT_RESERVATION =
    'PreReservedSeatAssignment'

export const OPTIONAL_SERVICE_SECONDARY_TYPE_CHANGE = 'VC'
export const OPTIONAL_SERVICE_SECONDARY_TYPE_REFUND = 'RF'

// Hatec reservation statuses

export const HATEC_RESERVATION_STATUS_FAILED = 4
export const HATEC_RESERVATION_STATUS_CREATED = 0
export const HATEC_RESERVATION_STATUS_PENDING = 1
export const HATEC_RESERVATION_STATUS_CONFIRMED = 2
export const HATEC_RESERVATION_STATUS_CANCELLED = 3

// VCC

// In the Cherimoya's CC api this provider is defined incorrectly
// They let us know they don't intend to fix this in the near future so unfortunately we have to solve it like this.
export const CC_PROVIDER_ELSYARRES = 'ElsyArress'

// Greentripper classes

export const GREENTRIPPER_COMMISSION_PERCENTAGE = 12

export const GREEN_TRIPPER_CLASS_ECO = 'eco'
export const GREEN_TRIPPER_CLASS_FIRST = 'first'
export const GREEN_TRIPPER_CLASS_PREMIUM = 'premium'
export const GREEN_TRIPPER_CLASS_BUSINESS = 'business'
export const GREEN_TRIPPER_CLASS_TYPES = {
    [HATEC_CLASS_FIRST]: GREEN_TRIPPER_CLASS_FIRST,
    [HATEC_CLASS_ECONOMY]: GREEN_TRIPPER_CLASS_ECO,
    [HATEC_CLASS_ECONOMY_PLUS]: GREEN_TRIPPER_CLASS_ECO,
    [HATEC_CLASS_BUSINESS]: GREEN_TRIPPER_CLASS_BUSINESS,
}

// Travelbooster pax types

export const TRAVEL_BOOSTER_PAX_FILE_ADULT = 'Adult'
export const TRAVEL_BOOSTER_PAX_FILE_CHILD = 'Child'
export const TRAVEL_BOOSTER_PAX_FILE_INFANT = 'Infant'
export const TRAVEL_BOOSTER_PAX_FILE_SENIOR = 'Senior'
export const TRAVEL_BOOSTER_PAX_FILE_STUDENT = 'Student'
export const TRAVEL_BOOSTER_PAX_FILE_CUSTOMER_TYPES_BY_TRAVELER_TYPE = {
    [TRAVELER_TYPE_CHILD]: [TRAVEL_BOOSTER_PAX_FILE_CHILD],
    [TRAVELER_TYPE_INFANT]: [TRAVEL_BOOSTER_PAX_FILE_INFANT],
    [TRAVELER_TYPE_ADULT]: [
        TRAVEL_BOOSTER_PAX_FILE_ADULT,
        TRAVEL_BOOSTER_PAX_FILE_SENIOR,
        TRAVEL_BOOSTER_PAX_FILE_STUDENT,
    ],
}

// Jobs

export const JOB_REMOVE_STALE_JOURNEYS = 'remove-stale-journeys'
export const JOB_SYNC_FLIGHT_SEARCH_RESULTS = 'sync-search-results'
export const JOB_SET_FLIGHT_ABANDONED_BASKET = 'set-flight-abonded-basket'
export const JOB_CHECK_FLIGHT_BOOKING_PAYMENT = 'check-flight-booking-payment'

// Email templates

export const EMAIL_TEMPLATE_ALIAS_FORM_SUBMIT = 'form-submit'
export const EMAIL_TEMPLATE_ADMIN_INVITATION = 'admin-invitation'
export const EMAIL_TEMPLATE_ALIAS_BOOKING_FAILED = 'booking-failed'
export const EMAIL_TEMPLATE_ALIAS_BOOKING_COMPLETED = 'booking-completed'
export const EMAIL_TEMPLATE_ALIAS_FLIGHT_CONFIRMATION = 'flight-confirmation'
export const EMAIL_TEMPLATE_ALIAS_GIFTCARD_CONFIRMATION =
    'giftcard-confirmation'
export const EMAIL_TEMPLATE_ALIAS_BOOKING_FAILED_SUPPORT =
    'booking-failed-support'
export const EMAIL_TEMPLATE_ALIAS_FLIGHT_BOOKING_FAILED_SUPPORT =
    'flight-booking-failed-support'

// Nezasa

export const DETAIL_IMPORTANCE_HIDDEN = 'hidden'
export const DETAIL_IMPORTANCE_OPTIONAL = 'optional'
export const DETAIL_IMPORTANCE_REQUIRED = 'required'
export const DETAIL_IMPORTANCES = [
    DETAIL_IMPORTANCE_HIDDEN,
    DETAIL_IMPORTANCE_OPTIONAL,
    DETAIL_IMPORTANCE_REQUIRED,
]

export const NEZASA_TRAVEL_CLASS_FIRST = 'First'
export const NEZASA_TRAVEL_CLASS_SECOND = 'Second'
export const NEZASA_TRAVEL_CLASS_ECONOMY = 'Economy'
export const NEZASA_TRAVEL_CLASS_BUSINESS = 'Business'
export const NEZASA_TRAVEL_CLASS_PREMIUM_ECONOMY = 'PremiumEconomy'
export const GREEN_TRIPPER_CLASSES_NEZASA_TRAVEL_CLASSES = {
    [NEZASA_TRAVEL_CLASS_FIRST]: GREEN_TRIPPER_CLASS_FIRST,
    [NEZASA_TRAVEL_CLASS_ECONOMY]: GREEN_TRIPPER_CLASS_ECO,
    [NEZASA_TRAVEL_CLASS_BUSINESS]: GREEN_TRIPPER_CLASS_BUSINESS,
    [NEZASA_TRAVEL_CLASS_PREMIUM_ECONOMY]: GREEN_TRIPPER_CLASS_PREMIUM,
}

// Product types

export const PRODUCT_TYPE_EMISSION_COSTS = 'EMISSION_COSTS'
export const PRODUCT_TYPE_FILE_COSTS = 'FILE_COSTS'

// Payments
export const PAYMENT_AMOUNT_CUSTOM = 'PAYMENT_AMOUNT_CUSTOM'
export const PAYMENT_AMOUNT_FULL = 'PAYMENT_AMOUNT_FULL'
export const PAYMENT_AMOUNT_DOWNPAYMENT = 'PAYMENT_AMOUNT_DOWNPAYMENT'
export const TRANSACTION_COMPLETED = 'TRANSACTION_COMPLETED'
export const TRANSACTION_ABORTED = 'TRANSACTION_ABORTED'
export const TRANSACTION_PENDING = 'TRANSACTION_PENDING'

// NDC
export const CABIN_CLASS_CODE_FIRST = '1'
export const CABIN_CLASS_CODE_BUSINESS = '2'
export const CABIN_CLASS_CODE_PREMIUM_ECONOMY = '4'
export const CABIN_CLASS_CODE_ECONOMY = '5'

export const CHECKOUT_FLOW_APP = 'APP'
export const CHECKOUT_FLOW_IBE = 'IBE'
export const CHECKOUT_FLOWS = [CHECKOUT_FLOW_APP, CHECKOUT_FLOW_IBE]

export const PAYMENT_METHOD_CASH = 'Cash'
export const PAYMENT_METHOD_TERMINAL = 'Terminal'
export const TERMINAL_DRIVER_WORLDLINE = 'worldline'
export const MAXIMUM_ALLOWED_CASH = 3000
export const HIDDEN_PATHS = ['_test', 'hidden', 'preview', 'algemeen']

export const PRODUCTION = 'production'
export const STAGING = 'staging'
export const DEVELOPMENT = 'development'
